.kubeapps-header {
  width: 100%;
  height: auto;
  padding-top: 0.3rem;
  border-bottom: 2px solid var(--cds-alias-object-overlay-background, #f1f1f1);
  background-color: var(--cds-alias-object-app-background, #fff);
}
.kubeapps-header .img-container {
  display: contents;
  align-items: center;
}
.kubeapps-header .img-container img {
  max-width: 7rem;
  max-height: 3rem;
  margin: 0.2rem 0 0.2rem 0.6rem;
}
.kubeapps-header .kubeapps-header-content {
  width: 100%;
}
.kubeapps-header .kubeapps-header-content .kubeapps-title-section {
  display: flex;
  min-height: 60px;
}
.kubeapps-header .kubeapps-header-content .kubeapps-title-section .kubeapps-title-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.kubeapps-header .kubeapps-header-content .kubeapps-title-section .kubeapps-title-block h1 {
  padding-top: 0.2rem;
  margin: 0;
  margin-left: 0.6rem;
}
.kubeapps-header .kubeapps-header-content .kubeapps-title-section .kubeapps-title-block h3 {
  margin-top: 0;
  margin-left: 1rem;
  font-weight: 500;
}
.kubeapps-header .kubeapps-header-content .kubeapps-title-section .kubeapps-title-block .kubeapps-header-subtitle {
  margin-left: 1rem;
}
.kubeapps-header .kubeapps-header-content .kubeapps-title-section .kubeapps-title-block .kubeapps-header-subtitle img {
  max-width: 30px;
  max-height: 1rem;
  padding-right: 0.2rem;
  margin: 0;
}
.kubeapps-header .kubeapps-header-content .kubeapps-title-section .kubeapps-title-block .kubeapps-header-subtitle span {
  padding-top: 0.1rem;
  color: var(--cds-global-typography-color-400, #333);
}
.kubeapps-header .kubeapps-header-content .control-buttons {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}
.kubeapps-header .kubeapps-header-content .control-buttons .header-button {
  margin-left: 0.5rem;
}

.header-version-label {
  margin-right: 0.6rem;
  color: var(--cds-global-typography-color-400, #333);
  font-weight: 500;
}
.header-version button {
  color: var(--cds-global-typography-color-400, #333);
}
